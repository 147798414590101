<template>
  <div>
    <KTCodePreview v-bind:title="'Danh sách phiếu yêu cầu nhập hàng'">
      <template v-slot:toolbar>
        <div class="row">
          <div v-if="false">
            <b-dropdown size="sm" id="dropdown-1" right>
              <template slot="button-content">
                <i style="font-size: 1rem" class="fas fa-cog"></i>
                <span class="font-weight-bolder">Thao tác</span>
              </template>
              <b-dropdown-item @click="reportClick">
                <span>
                  <i style="font-size: 1rem" class="far fa-file-excel"></i>
                  &nbsp; Xuất Excel</span
                >
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="checkPermission('PO_INSERT')" class="ml-3">
            <router-link to="/purchase-orders/info-purchase-orders">
              <b-button v-bind:style="btnCreate" variant="primary" size="sm">
                <i style="font-size: 1rem" class="flaticon2-add-1"></i>Thêm mới
              </b-button>
            </router-link>
          </div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <b-row class="mb-5">
          <b-col>
            <div class="search-col">
              <date-picker
                placeholder="Từ ngày"
                class="form-control-sm"
                :config="dpConfigs.start"
                v-model="dpForm.startDate"
              ></date-picker>
              <span class="ml-1 mr-1"></span>
              <date-picker
                placeholder="Đến ngày"
                class="form-control-sm"
                :config="dpConfigs.end"
                v-model="dpForm.endDate"
              ></date-picker>
            </div>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedType"
              :options="listType"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col>
            <vue-autosuggest
              v-model="searchProduct"
              :suggestions="filteredOptionsProduct"
              @selected="onSelectedProduct"
              :limit="10"
              @input="onInputChangeProduct"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Nhập mã, tên, mã vạch sản phẩm',
              }"
              :should-render-suggestions="
                (size, loading) => size >= 0 && !loading && searchProduct !== ''
              "
              ><div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                {{ suggestion.item.productName }}
              </div>
            </vue-autosuggest>
          </b-col>
          <b-col>
            <b-form-select
              size="sm"
              v-model="selectedStatus"
              :options="listStatus"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <b-form-input
              v-model="searchId"
              type="text"
              placeholder="Mã phiếu"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <b-form-input
              v-model="search"
              type="text"
              placeholder="Tìm kiếm nội dung"
              size="sm"
              v-on:keyup.enter="onFilter()"
            ></b-form-input>
          </b-col>
          <b-col>
            <vue-autosuggest
              v-model="searchStock"
              :suggestions="filteredOptions"
              @selected="onSelected"
              :limit="10"
              @input="onInputChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Kho',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.suggestionName }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
          <b-col>
            <vue-autosuggest
              v-model="searchProvider"
              :suggestions="filteredProviderOptions"
              @selected="onProviderSelected"
              :limit="10"
              @input="onSearchProviderChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Nhà cung cấp',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.name }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedImportStatus"
              :options="listImportStatus"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col md="3">
            <vue-autosuggest
              v-model="searchEmployee"
              :suggestions="filteredEmployeeOptions"
              @selected="onSelectedEmployee"
              :limit="10"
              @input="onSearchEmployeeChange"
              :input-props="{
                class: 'autosuggest__input',
                placeholder: 'Người tạo',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.suggestionName }}
                </div>
              </div>
            </vue-autosuggest>
          </b-col>
          <b-col md="3">
            <b-form-select
              size="sm"
              v-model="selectedCompany"
              :options="listCompany"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="onFilter"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listItem"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>

          <template v-slot:cell(code)="row">
            <div
              style="
                justify-content: center;
                align-items: center;
                display: grid;
              "
            >
              <a class="font-weight-bold" v-bind:href="getLinkDoc(row.item)">
                {{ row.item.code }}
              </a>
              <br />
              <p>{{ row.item.createdAt }}</p>
              <p>{{ row.item.createdByName }}</p>
            </div>
          </template>
          <template v-slot:cell(providerName)="row">
            <span v-text="row.item.providerName"></span>
            <div>
              <p class="text-success">
                {{ row.item.typeName }}
              </p>
            </div>
          </template>
          <template v-slot:cell(statusName)="row">
            <div>
              <span
                v-text="row.item.statusName"
                class="label font-weight-bold label-lg label-inline"
                v-bind:class="{
                  'label-light-warning': row.item.status === 1,
                  'label-light-success': row.item.status === 3,
                  'label-light-danger': row.item.status === 4,
                  'label-light-info': row.item.status === 2,
                  'label-light-default': row.item.status === 6,
                  'label-light-dark': row.item.status === 5,
                }"
                style="width: max-content"
              ></span>
            </div>
          </template>
          <template v-slot:cell(storeName)="row">
            <div class="font-weight-bolder">
              <span v-text="row.item.storeName"></span>
            </div>
          </template>
          <template v-slot:cell(description)="row">
            <div @mouseover="hoverNote = true" @mouseleave="hoverNote = false">
              <span v-text="row.item.importReason" class="mr-2"></span>
              <p v-if="row.item.description && row.item.importReason">
                --------------
              </p>
              <span v-text="row.item.description" class="mr-2"></span>
              <span @click="handleShowNoteModel(row.item)">
                <i v-if="hoverNote" class="fas fa-edit"></i>
              </span>
            </div>
          </template>
          <template v-slot:cell(payment)="row">
            <p class="text-primary">
              Tổng tiền: {{ formatMoney(row.item.totalAmount) }}
            </p>
            <p class="border-0" v-if="row.item.paymentAmount > 0">
              <i
                v-b-tooltip.hover
                :title="'Đã thanh toán'"
                class="fas fa-money-bill-alt icon-color"
              ></i>
              : {{ formatMoney(row.item.paymentAmount) }}
            </p>
            <p class="text-danger" v-else>Chưa thanh toán</p>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i
                    style="font-size: 1rem; padding-right: 0px"
                    class="flaticon2-settings"
                  ></i>
                </template>
                <b-dropdown-item @click="printItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-printer"></i>
                    &nbsp; In phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel(row.item.id)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="far fa-file-excel"></i>
                    &nbsp; Xuất excel
                  </span>
                </b-dropdown-item>
                <b-dropdown-item @click="copyItem(row.item)">
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="far fa-copy"></i>
                    &nbsp; Sao chép
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="editItem(row.item)"
                  v-if="checkEdit(row.item)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-pen"></i>
                    &nbsp; Chỉnh sửa
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="requestForApprove(row.item)"
                  v-if="row.item.status === PURCHASE_ORDER_STATUS.NEW"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="fa fa-paper-plane"></i>
                    &nbsp; Yêu cầu duyệt
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="approvePO(row.item.id)"
                  v-if="row.item.status === PURCHASE_ORDER_STATUS.READY"
                  v-show="checkPermission('PO_PURCHASE_APPROVE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="fa fa-angle-double-right"
                    ></i>
                    &nbsp; NH duyệt
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="approvePO(row.item.id)"
                  v-if="row.item.status === PURCHASE_ORDER_STATUS.PURCHASE"
                  v-show="checkPermission('PO_ACCOUNT_APPROVE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem"
                      class="fa fa-angle-double-right"
                    ></i>
                    &nbsp; KT duyệt
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="addStockSlip(row.item)"
                  v-if="checkCreateStockSlip(row.item)"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="flaticon2-add-1"></i>
                    &nbsp; Tạo phiếu nhập
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="addExportStockSlip(row.item)"
                  v-if="row.item.totalImportQuantity"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i style="font-size: 1rem" class="fas fa-file-export"></i>
                    &nbsp; Tạo phiếu xuất trả
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showModalCancel(row.item)"
                  v-if="checkCancel(row.item)"
                  v-show="checkPermission('PO_CANCEL')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i class="fas fa-ban"></i>
                    &nbsp; Trả phiếu
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  @click="showDeleteAlert(row.item)"
                  v-if="checkPermission('PO_DELETE')"
                >
                  <span style="color: #3f4254; font-size: 12px">
                    <i
                      style="font-size: 1rem; color: #d33"
                      class="flaticon2-rubbish-bin-delete-button"
                    ></i>
                    &nbsp; Xóa
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark" style="font-weight: 500">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <b-pagination-nav
              class="customPagination"
              v-show="totalPages >= 2"
              :link-gen="linkGen"
              :number-of-pages="totalPages"
              use-router
              @change="fetchData"
              align="right"
              first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
              prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
              next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
              last-class="page-item-last btn btn-icon btn-sm my-1 "
              page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
            >
              <template v-slot:first-text>
                <span>
                  <i class="ki ki-bold-double-arrow-back icon-xs"></i>
                </span>
              </template>

              <template v-slot:prev-text>
                <i class="ki ki-bold-arrow-back icon-xs"></i>
              </template>

              <template v-slot:next-text>
                <i class="ki ki-bold-arrow-next icon-xs"></i>
              </template>

              <template v-slot:last-text>
                <span class="text-info">
                  <i class="ki ki-bold-double-arrow-next icon-xs"></i>
                </span>
              </template>
            </b-pagination-nav>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
        <template>
          <b-modal
            v-model="showEditNoteModal"
            hide-footer
            :title="
              'Sửa ghi chú/ lý do nhập hàng cho phiếu: ' + purchaseOrderCode
            "
          >
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Lý do nhập hàng</label
                >
                <b-form-textarea
                  v-model="importNote"
                  placeholder="Nhập lý do nhập hàng..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row class="mb-5">
              <b-col>
                <label class="labelInput" for="inputOriginalPrice"
                  >Ghi chú</label
                >
                <b-form-textarea
                  v-model="note"
                  placeholder="Nhập ghi chú..."
                  rows="5"
                  max-rows="6"
                ></b-form-textarea>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  style="fontweight: 600; width: 70px"
                  variant="primary"
                  size="sm"
                  @click="updateNote"
                  >Lưu</b-button
                >
                <b-button
                  style="margin-left: 10px; font-weight: 600; width: 70px"
                  variant="secondary"
                  size="sm"
                  @click="handleShowNoteModel"
                  >Hủy</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
        </template>
        <CancelPO
          ref="po-cancel"
          :purchaseOrderCode="purchaseOrderCode"
          :purchaseOrderId="purchaseOrderId"
          v-on:onSuccess="fetchData"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import moment from 'moment';
import { VueAutosuggest } from 'vue-autosuggest';
import { VclTable } from 'vue-content-loading';
import Swal from 'sweetalert2';
// Import datePicker component
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
// Using font-awesome 5 icons
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/regular.css';
import '@fortawesome/fontawesome-free/css/solid.css';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import decounce from 'debounce';
import localData from '../../../utils/saveDataToLocal';
import { PURCHASE_ORDER_STATUS } from '../../../utils/enum';
import _ from 'lodash';
import CancelPO from '../../components/stock-slips/CancelPO';
import {
  PURCHASE_ORDER_TYPE,
  BASE_URL,
  TIME_TRIGGER,
} from './../../../utils/constants';
import fileDownload from '../../../utils/file-download';
import axios from 'axios';
import 'vue-cute-timeline/dist/index.css';
import { xoa_dau } from '../../../utils/common';

export default {
  data() {
    return {
      dpForm: {
        startDate: moment().subtract(15, 'days').format('DD/MM/YYYY'),
        endDate: '',
      },
      dpConfigs: {
        start: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
        end: {
          format: 'DD/MM/YYYY',
          useCurrent: false,
          showClear: true,
          showClose: true,
          minDate: false,
          maxDate: false,
          locale: 'vi',
          sideBySide: true,
        },
      },
      onLoading: false,
      listItem: [],
      search: '',
      searchStock: '',
      searchId: '',
      totalPages: 1,
      count: 1,
      totalItems: 0,
      page: 1,
      status: '',
      store: '',
      searchFromDay: '',
      searchToDay: '',
      type: 5,
      fields: [
        {
          key: 'code',
          label: 'Mã phiếu',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'storeName',
          label: 'Kho',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'providerName',
          label: 'NCC',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'totalProductCount',
          label: 'SL SP',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'totalProductQuantity',
          label: 'Tổng SL',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'totalImportQuantity',
          label: 'SL đã nhập',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '5%' },
          tdClass: 'text-right',
          formatter: (value) => {
            return value ? this.formatMoney(value) : 0;
          },
        },
        {
          key: 'statusName',
          label: 'Trạng thái',
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'payment',
          label: 'Thanh toán',
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'description',
          label: 'Nội dung',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '25%' },
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      selectedStoreId: null,
      options: [
        {
          data: [],
        },
      ],
      filteredOptions: [],
      limit: 10,
      searchProvider: '',
      selectedProvider: null,
      optionsProvider: [
        {
          data: [],
        },
      ],
      filteredProviderOptions: [],
      searchProduct: '',
      selectedStatus: null,
      listStatus: [
        {
          id: null,
          name: 'Tất cả trạng thái',
        },
        {
          id: PURCHASE_ORDER_STATUS.NEW,
          name: 'Mới',
        },
        {
          id: PURCHASE_ORDER_STATUS.READY,
          name: 'Yêu cầu duyệt',
        },
        {
          id: PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL,
          name: 'Ngành hàng trả phiếu',
        },
        {
          id: PURCHASE_ORDER_STATUS.PURCHASE,
          name: 'Ngành hàng duyệt',
        },
        {
          id: PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCEL,
          name: 'Kế toán trả phiếu',
        },

        {
          id: PURCHASE_ORDER_STATUS.ACCOUNTANT,
          name: 'Kế toán duyệt',
        },
      ],
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      hoverNote: false,
      purchaseOrderCode: '',
      showEditNoteModal: false,
      note: '',
      importNote: '',
      userStoreId: null,
      listProvider: [],
      btnCreate: {
        fontWeight: '600!important',
      },
      PURCHASE_ORDER_STATUS: PURCHASE_ORDER_STATUS,
      purchaseOrderId: null,
      selectedType: null,
      listType: PURCHASE_ORDER_TYPE,
      selectedEmployeeId: null,
      optionsEmployee: [
        {
          data: [],
        },
      ],
      filteredEmployeeOptions: [],
      searchEmployee: '',
      selectedImportStatus: null,
      listImportStatus: [
        {
          id: null,
          name: 'Trạng thái nhập kho',
        },
        {
          id: 1,
          name: 'Nhập đủ',
        },
        {
          id: 2,
          name: 'Nhập thiếu',
        },
        {
          id: 3,
          name: 'Nhập dư',
        },
        {
          id: 4,
          name: 'Chưa nhập hàng',
        },
      ],
      selectedCompany: null,
      listCompany: [],
    };
  },
  methods: {
    fetchStore: async function () {
      this.options[0].data = [];
      ApiService.get('/stores/getStores').then((response) => {
        const stores = response.data.data;
        stores.map((element) => {
          const store = {
            id: element.id,
            name: element.name,
            shortName: element.shortName,
            suggestionName: element.shortName + ' ( ' + element.name + ')',
          };
          this.options[0].data.push(store);
        });
        this.filteredOptions = [{ data: this.options[0].data }];
        this.filteredDStockOptions = [{ data: this.options[0].data }];
      });
    },
    fetchData: async function () {
      this.onLoading = true;
      this.page = this.$route.query.page;
      if (!this.page) {
        this.page = 1;
      }

      if (!this.searchProvider || this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }

      if (!this.searchStock || this.searchStock.trim() === '') {
        this.selectedStoreId = null;
      }

      if (!this.searchEmployee || this.searchEmployee.trim() === '') {
        this.selectedEmployeeId = null;
      }

      const params = {
        pageNumber: this.page,
        pageSize: 10,
        description: this.search ? this.search.trim() : '',
        code: this.searchId ? this.searchId.trim() : '',
        status: this.selectedStatus,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        storeId: this.selectedStoreId,
        providerId: this.selectedProvider,
        productInfo: this.searchProduct,
        type: this.selectedType,
        createdById: this.selectedEmployeeId,
        importStatus: this.selectedImportStatus,
        companyId: this.selectedCompany,
      };

      if (!params.description) delete params.description;
      if (!params.code) delete params.code;
      if (!params.status) delete params.status;
      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;
      if (!params.storeId) delete params.storeId;
      if (!params.providerId) delete params.providerId;
      if (!params.productInfo) delete params.productInfo;
      if (!params.type) delete params.type;
      if (!params.createdById) delete params.createdById;
      if (!params.importStatus) delete params.importStatus;
      if (!params.companyId) delete params.companyId;

      ApiService.query('purchase-order', { params })
        .then((response) => {
          const responseData = response.data.data;
          this.totalPages = responseData.totalPage;
          this.totalItems = responseData.total;
          const listData = _.orderBy(responseData.data, ['id'], ['desc']);
          this.listItem = listData.map((item, index) => {
            return {
              ...item,
              count: index + 1,
              createdBy: item.createdByName ? item.createdByName : '',
              createdAt: moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
              purchaseApproveByName: item.purchaseApproveByName
                ? item.purchaseApproveByName
                : '',
              purchaseApproveDate: item.purchaseApproveDate
                ? moment(item.purchaseApproveDate).format('DD/MM/YYYY HH:mm:ss')
                : '',
              accountantApproveByName: item.accountantApproveByName
                ? item.accountantApproveByName
                : '',
              accountantApproveDate: item.accountantApproveDate
                ? moment(item.accountantApproveDate).format(
                    'DD/MM/YYYY HH:mm:ss'
                  )
                : '',
            };
          });
          this.onLoading = false;
        })
        .catch(() => {
          this.onLoading = true;
        });
    },
    editItem: function (item) {
      this.$router.push({
        name: 'info-purchase-orders',
        query: { id: item.id },
      });
    },
    onSelected(option) {
      this.selectedStoreId = option.item.id;
      this.searchStock = option.item.name;
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }
      this.searchStock = text;

      /* Full control over filtering. Maybe fetch from API?! Up to you!!! */
      const filteredData = this.options[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredOptions = [
        {
          data: filteredData,
        },
      ];
    },
    /**
     * This is what the <input/> value is set to when you are selecting a suggestion.
     */
    getSuggestionValue(suggestion) {
      return suggestion.item.name;
    },
    showDeleteAlert: function (item) {
      Swal.fire({
        title: 'Xóa phiếu yêu cầu nhập hàng!',
        text: 'Bạn có chắc muốn xóa phiếu yêu cầu nhập hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Xóa',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.deleteItem(item);
        }
      });
    },
    deleteItem: async function (item) {
      ApiService.delete(`purchase-order/${item.id}`).then(({ data }) => {
        if (data.status === 1) {
          this.makeToastSuccess(data.message);
          this.fetchData();
        } else {
          this.makeToastFaile(data.message);
        }
      });
    },
    showModalCancel(item) {
      this.purchaseOrderId = item.id;
      this.purchaseOrderCode = item.code;
      this.$refs['po-cancel'].showModal();
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
    approvePO: function (id) {
      const { href } = this.$router.resolve({
        name: 'approve-purchase-orders',
        query: { id: id },
      });
      window.open(href, '_blank');
    },
    requestForApprove: function (item) {
      Swal.fire({
        title: 'Yêu cầu duyệt phiếu yêu cầu nhập hàng!',
        text: 'Bạn có chắc muốn yêu cầu duyệt phiếu yêu cầu nhập hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Có',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.requestForApproveItem(item.id);
        }
      });
    },
    requestForApproveItem(id) {
      ApiService.put(`purchase-order/request-for-approve/${id}`).then(
        ({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.fetchData();
          } else {
            this.makeToastFaile(data.message);
          }
        }
      );
    },
    viewTransferStock: function (item) {
      this.$router.push({
        name: 'update-transfer-stock',
        query: { id: item.relateStock },
      });
    },
    onProviderSelected(option) {
      this.selectedProvider = option.item.id;
      this.searchProvider = option.item.name;
    },
    onSearchProviderChange(text) {
      if (!text) {
        text = '';
      }
      this.searchProvider = text;

      const filteredData = this.optionsProvider[0].data
        .filter((item) => {
          return item.name.toLowerCase().indexOf(text.toLowerCase()) > -1;
        })
        .slice(0, this.limit);

      this.filteredProviderOptions = [
        {
          data: filteredData,
        },
      ];
    },
    fetchProduct() {
      this.optionsProduct[0].data = [];
      ApiService.setHeader();
      ApiService.get(
        `productSearch?searchProduct=${this.searchProduct}&storeId=&includeParent=true`
      ).then(({ data }) => {
        const products = data.data;
        products.map((element) => {
          this.optionsProduct[0].data.push(element);
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
      });
    },
    debounceInput: decounce(function () {
      this.fetchProduct();
    }, TIME_TRIGGER),
    onSelectedProduct(option) {
      this.searchProduct = option.item.productName;
    },
    onInputChangeProduct(text) {
      if (!text) {
        text = '';
      }
      this.searchProduct = text;
      this.debounceInput();
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-purchase-orders',
      });
      this.fetchData();
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    getLinkDoc(item) {
      return `#/purchase-orders/info-purchase-orders?id=${item.id}`;
    },
    handleShowNoteModel(item) {
      this.showEditNoteModal = !this.showEditNoteModal;
      this.purchaseOrderCode = item.code;
      this.note = item.description;
      this.importNote = item.importReason;
    },
    updateNote() {
      const data = {
        code: this.purchaseOrderCode,
        description: this.note,
        importReason: this.importNote,
      };

      ApiService.put('purchase-order/update-note', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            this.showEditNoteModal = !this.showEditNoteModal;
            this.fetchData();
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    fetchStoreByUser() {
      ApiService.setHeader();
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1) {
            this.userStoreId = stores[0].id;
          }
        }
      });
    },
    printItem: function (item) {
      this.$router.push({
        name: 'print-purchase-orders',
        query: { id: item.id },
      });
    },
    fetchProvider() {
      ApiService.get(`providers/getAll`).then(({ data }) => {
        this.listProvider = data.data.providers;
        const noneProvider = {
          id: null,
          name: 'Tất cả NCC',
        };
        this.listProvider.unshift(noneProvider);
        this.listProvider.map((element) => {
          this.optionsProvider[0].data.push(element);
        });
        this.filteredProviderOptions = [{ data: this.optionsProvider[0].data }];
      });
    },
    addStockSlip: function (item) {
      this.$router.push({
        name: 'add-stock',
        query: { purchaseOrderId: item.id },
      });
    },
    addExportStockSlip: function (item) {
      this.$router.push({
        name: 'add-export-stock',
        query: { purchaseOrderId: item.id },
      });
    },
    formatMoney(number) {
      if (!number) return 0;
      return new Intl.NumberFormat('vn-VN').format(number);
    },
    checkCreateStockSlip(item) {
      if (
        item.status === PURCHASE_ORDER_STATUS.ACCOUNTANT &&
        item.totalProductQuantity > item.totalImportQuantity
      ) {
        return true;
      }

      return false;
    },
    checkCancel(item) {
      if (
        item.status === PURCHASE_ORDER_STATUS.PURCHASE &&
        item.totalImportQuantity === 0
      ) {
        return true;
      }

      return false;
    },
    checkEdit(item) {
      const statusEditable = [
        PURCHASE_ORDER_STATUS.NEW,
        PURCHASE_ORDER_STATUS.PO_HEAD_CANCEL,
        PURCHASE_ORDER_STATUS.ACCOUNTANT_CANCEL,
      ];
      if (statusEditable.includes(item.status)) {
        return true;
      }

      return false;
    },
    reportClick: async function () {
      if (!this.searchProvider || this.searchProvider.trim() === '') {
        this.selectedProvider = null;
      }

      if (!this.searchStock || this.searchStock.trim() === '') {
        this.selectedStoreId = null;
      }

      const params = {
        description: this.search ? this.search.trim() : '',
        code: this.searchId ? this.searchId.trim() : '',
        status: this.selectedStatus,
        fromDate: this.dpForm.startDate
          ? moment(this.dpForm.startDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        toDate: this.dpForm.endDate
          ? moment(this.dpForm.endDate, 'DD/MM/YYYY').format('yyyy-MM-DD')
          : '',
        storeId: this.selectedStoreId,
        providerId: this.selectedProvider,
        productInfo: this.searchProduct,
        type: this.selectedType,
      };

      if (!params.description) delete params.description;
      if (!params.code) delete params.code;
      if (!params.status) delete params.status;
      if (!params.fromDate) delete params.fromDate;
      if (!params.toDate) delete params.toDate;
      if (!params.storeId) delete params.storeId;
      if (!params.providerId) delete params.providerId;
      if (!params.productInfo) delete params.productInfo;
      if (!params.type) delete params.type;

      const url = `${BASE_URL}purchase-order/export-excel`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
        params: params,
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    onSelectedEmployee(option) {
      this.selectedEmployeeId = option.item.id;
      this.searchEmployee = option.item.name;
    },
    onSearchEmployeeChange(text) {
      if (!text) {
        text = '';
      }
      this.searchEmployee = text;
      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          return (
            item.nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);

      this.filteredEmployeeOptions = [
        {
          data: filteredData,
        },
      ];
    },
    fetchEmployee: async function () {
      this.optionsEmployee[0].data = [];
      ApiService.get('/employees/get-list-filter').then((response) => {
        const employees = response.data.data;
        this.optionsEmployee[0].data = employees.map((element) => {
          let suggestionName = element.name;
          if (element.code) {
            suggestionName += ' ( ' + element.code + ')';
          }
          const nameNoneSign = xoa_dau(element.name);
          return {
            id: element.id,
            name: element.name,
            code: element.code,
            suggestionName: suggestionName,
            nameNoneSign: nameNoneSign,
          };
        });
        this.filteredEmployeeOptions = [{ data: this.optionsEmployee[0].data }];
      });
    },
    exportExcel: async function (purchaseOrderId) {
      const url = `${BASE_URL}purchase-order/export-excel-detail/${purchaseOrderId}`;
      await axios({
        url: url,
        method: 'GET',
        responseType: 'blob',
      }).then((response) => {
        const fileName = fileDownload.getFileName(response);
        fileDownload.createFile(response.data, fileName);
      });
    },
    copyItem: function (item) {
      this.$router.push({
        name: 'info-purchase-orders',
        query: { id: item.id, isCreate: true },
      });
    },
    fetchCompany: async function () {
      ApiService.get('/company').then((response) => {
        this.listCompany = response.data.data;
        this.listCompany.unshift({
          id: null,
          name: 'Chọn doanh nghiệp',
        });
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Phiếu yêu cầu nhập hàng', route: 'purchase-orders' },
      { title: 'Danh sách phiếu yêu cầu nhập hàng' },
    ]);
  },
  created() {
    let requireCode = this.$route.query.code;
    if (requireCode !== undefined) {
      this.searchId = requireCode;
    }
    this.listType.unshift({
      id: null,
      name: 'Tất cả hình thức thanh toán',
    });
    this.fetchData();
    this.fetchStore();
    this.fetchStoreByUser();
    this.fetchProvider();
    this.fetchEmployee();
    this.fetchCompany();
  },
  components: {
    KTCodePreview,
    VueAutosuggest,
    VclTable,
    datePicker,
    CancelPO,
  },
};
</script>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

select {
  height: 2.5em;
}

.example {
  border: 1px solid #ddd;
  margin-bottom: 2em;
}

code,
pre {
  margin: 1em 0;
  padding: 1em;
  border: 1px solid #bbb;
  display: block;
  background: #ddd;
  border-radius: 3px;
}

.settings {
  margin: 2em 0;
  border-top: 1px solid #bbb;
  background: #eee;
}

.form-group {
  margin-bottom: 1em;
}

.btn.btn-icon.btn-sm,
.btn-group-sm > .btn.btn-icon {
  height: calc(1.35em + 1.1rem + 2px);
  width: auto;
}
.customPagination /deep/ .page-item-first .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item-prev .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-next .page-link {
  background-color: #ecf1f6;
}
.customPagination /deep/ .page-item-last .page-link {
  background-color: #ecf1f6;
}

.customPagination /deep/ .page-item .page-link {
  border-radius: 0.42rem;
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  -webkit-box-align: center;
  height: 2.25rem;
  min-width: 2.25rem;
  padding: 0.5rem;
  color: #7e8299;
}

.customPagination /deep/ .page-item {
  color: #ecf1f6;
}

.customPagination /deep/ .page-item .router-link-exact-active {
  color: #fff;
}

.customPagination /deep/ .page-item .page-link:hover {
  background-color: #3699ff;
  color: #fff;
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  opacity: 1;
}

.icon:hover {
  background-color: #90c6fc;
}

.sttClass {
  width: 20px;
}

.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-datetime-edit-year-field:not([aria-valuenow]),
::-webkit-datetime-edit-month-field:not([aria-valuenow]),
::-webkit-datetime-edit-day-field:not([aria-valuenow]) {
  color: transparent;
}
.productCode:hover {
  text-decoration: underline;
}
.search-col {
  display: flex;
  align-items: center;
}
.append {
  font-size: 0.8em;
  margin-top: 3px;
  color: #646c7c;
}
.po-head {
  font-size: 1em;
  margin-top: 3px;
  color: #5d7cb9;
}
</style>
